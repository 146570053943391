// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import chat from '@src/views/apps/chat/store/reducer'
import todo from '@src/views/apps/todo/store/reducer'
import users from '@src/views/apps/user/store/reducer'
import category from '@src/views/pages/category/store/reducer'
import packages from '@src/views/pages/packages/store/reducer'
import email from '@src/views/apps/email/store/reducer'
import invoice from '@src/views/apps/invoice/store/reducer'
import calendar from '@src/views/apps/calendar/store/reducer'
import ecommerce from '@src/views/apps/ecommerce/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import blog from '@src/views/pages/blog/store/reducer'
import backgroundhistory from '@src/views/pages/backgroundhistory/store/reducer'
import location from '@src/views/pages/location/store/reducer'
import generalinformation from '@src/views/pages/generalinformation/store/reducer'
import safetyinformation from '@src/views/pages/safetyinformation/store/reducer'
import city from '@src/views/pages/city/store/reducer'
import thingstodo from '@src/views/pages/thingstodo/store/reducer'
import howtoreach from '@src/views/pages/howtoreach/store/reducer'
import religiousplaces from '@src/views/pages/religiousplaces/store/reducer'
import hotel from '@src/views/pages/hotel/store/reducer'
import houseboats from '@src/views/pages/houseboats/store/reducer'
import sightseeing from '@src/views/pages/sightseeing/store/reducer'
import restourants from '@src/views/pages/restourants/store/reducer'
import thingstobenoted from '@src/views/pages/thingstobenoted/store/reducer'
import hospitals from '@src/views/pages/hospitals/store/reducer'
import pharmacies from '@src/views/pages/pharmacies/store/reducer'
import shoppingplaces from '@src/views/pages/shoppingplaces/store/reducer'
import collagesandschools from '@src/views/pages/collagesandschools/store/reducer'
import topdestination from '@src/views/pages/topdestination/store/reducer'
import slider from '@src/views/pages/slider/store/reducer'
import herosection from '@src/views/pages/herosection/store/reducer'
import howitworks from '@src/views/pages/howitworks/store/reducer'
import faqs from '@src/views/pages/faqs/store/reducer'
import tags from '@src/views/pages/tags/store/reducer'
import testimonial from '@src/views/pages/testimonial/store/reducer'
import contactdetail from '@src/views/pages/contactdetail/store/reducer'
import seo from '@src/views/pages/seo/store/reducer'
import whykashmironline from '@src/views/pages/whykashmironline/store/reducer'
import car from '@src/views/pages/car/store/reducer'
import itenery from '@src/views/pages/itenery/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  category,
  packages,
  blog,
  backgroundhistory,
  location,
  generalinformation,
  safetyinformation,
  city,
  thingstodo,
  howtoreach,
  religiousplaces,
  hotel,
  houseboats,
  sightseeing,
  restourants,
  thingstobenoted,
  hospitals,
  pharmacies,
  shoppingplaces,
  collagesandschools,
  topdestination,
  slider,
  herosection,
  howitworks,
  faqs,
  tags,
  testimonial,
  contactdetail,
  seo,
  whykashmironline,
  car,
  itenery
})

export default rootReducer
