// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null
}

const houseboats = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_HOUSEBOATSDATA':
      return { ...state, allData: action.data }
    case 'ADD_HOUSEBOATS':
      return { ...state, data: action.response }
    case 'EDIT_HOUSEBOATS':
      return { ...state, data: action.response }
    case 'DELETE_HOUSEBOATS':
      return { ...state }
    default:
      return { ...state }
  }
}
export default houseboats
