import mock from "../mock"
import { paginateArray } from "../utils"

const data = {
  users: [
    {
      id: 1,
      firstname: "Beverlie",
      lastname: "Krabbe",
      status: "pending",
      doc: "9 Oct 2022",
      avatar: require("@src/assets/images/avatars/10.png").default
    },
    {
      id: 2,
      firstname: "Karena",
      lastname: "Courtliff",
      status: "active",
      doc: "15 Oct 2022",
      avatar: require("@src/assets/images/avatars/11.png").default
    },
    {
      id: 3,
      firstname: "Corrie ",
      lastname: "Perot",
      status: "pending",
      doc: "12 Oct 2022",
      avatar: require("@src/assets/images/avatars/3.png").default
    },
    {
      id: 4,
      firstname: "Micaela",
      lastname: "McNirlan",
      status: "inactive",
      doc: "28 Oct 2022",
      avatar: require("@src/assets/images/avatars/2.png").default
    },
    {
      id: 5,
      firstname: "Edwina",
      lastname: "Baldetti",
      status: "pending",
      doc: "12 Oct 2022",
      avatar: require("@src/assets/images/avatars/1.png").default
    }
  ]
}

// GET ALL DATA
mock.onGet("/api/users/list/all-data").reply(200, data.users)

// POST: Add new user
mock.onPost("/apps/users/add-user").reply((config) => {
  // Get event from post data
  const user = JSON.parse(config.data)

  const { length } = data.users
  let lastIndex = 0
  if (length) {
    lastIndex = data.users[length - 1].id
  }
  user.id = lastIndex + 1

  data.users.unshift(user)

  return [201, { user }]
})

// GET Updated DATA
mock.onGet("/api/users/list/data").reply((config) => {
  const {
    q = "",
    perPage = 10,
    page = 1,
    role = null,
    currentPlan = null,
    status = null
  } = config

  /* eslint-disable  */
  const queryLowered = q.toLowerCase()
  const filteredData = data.users.filter(
    (user) =>
      (user.username.toLowerCase().includes(queryLowered) ||
        user.fullName.toLowerCase().includes(queryLowered)) &&
      user.role === (role || user.role) &&
      user.currentPlan === (currentPlan || user.currentPlan) &&
      user.status === (status || user.status)
  )
  /* eslint-enable  */

  return [
    200,
    {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

// GET USER
mock.onGet("/api/users/user").reply((config) => {
  const { id } = config
  const user = data.users.find((i) => i.id === id)
  return [200, { user }]
})

// DELETE: Deletes User
mock.onDelete("/apps/users/delete").reply((config) => {
  // Get user id from URL
  let userId = config.id

  // Convert Id to number
  userId = Number(userId)

  const userIndex = data.users.findIndex((t) => t.id === userId)
  data.users.splice(userIndex, 1)

  return [200]
})
